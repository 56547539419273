<template>
  <div class="page">
    <div class="yinying" v-if="!isOn"></div>

    <div class="home">
      <div class="return main" @click="goHome"><span>返回首页</span></div>
    </div>
    <!--  -->
    <div class="bg"></div>
    <!--  -->
    <div class="inner">
      <div class="inner_title m_flex_box flex_jz flex_vc">
      </div>
      <div v-html="inner.txt" class="newscontent"></div>
    </div>
    <!-- <div class="fixed">
      <i class="iconfont eye">&#xe8c7;</i>
      <i class="iconfont fenxiang">&#xe7fb;</i>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOn: true,
      hoverLi: 1,
      id: "",
      inner: "",
      fullPath: '',
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetail(this.id);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fullPath = from ? from.fullPath : '';
    });
  },
  beforeRouteLeave(to, from, next) {
    if(to.path == '/news' && to.fullPath == this.fullPath) {
      this.$store.dispatch('saveView', ['News', 'Home']).then(() => {
        next()
      })
    } else {
      this.$store.dispatch('saveView', ['Home']).then(() => {
        next()
      })
    }
  },
  methods: {
    getDetail(id) {
      this.$http
        .get("api.do?callOne&code=40289fd48649f4da01864e0890750022", {
          params: { val: id },
        })
        .then((res) => {
          this.inner = res.result;
        });
    },
    goHome() {
      this.$router.push("/");
    },
    addClass() {
      this.isOn = false;
    },
    addLi(i) {
      this.hoverLi == i;
    },
  },
};
</script>
<style scoped lang="scss">
@import "newsDetail.scss";
</style>
